import { useCallback, useEffect, useState, type ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useSearchParams } from "react-router-dom";

import Link from "common/core/link";
import { useViewer } from "util/viewer_wrapper";
import TextField from "common/form/fields/text";
import AddressSubform, {
  ADDRESS_FIELDS,
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import FormRow from "common/form/elements/row";
import WorkflowModal from "common/modals/workflow_modal";
import {
  Payer,
  UserRole,
  type UpdateTitleAgencyInput,
  PartnerPayerSourcesEnum,
} from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import { LogoNav } from "common/app_frame/logo_nav";
import { useActiveOrganization } from "common/account/active_organization";
import { includesOrgAdminRole } from "util/organization";
import { NONONBOARDED } from "title_portal/sign_up";
import type {
  TitleRouterViewer_viewer as Viewer,
  TitleRouterViewer_viewer_user as User,
} from "title_portal/router/viewer_query.graphql";
import { composeValidators } from "util/form";
import { validateIf, validatePresence } from "validators/form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { validateEmailFormat } from "validators/account";
import { captureException } from "util/exception";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import {
  TITLE_SUPPLEMENT_URL,
  SUBSCRIBER_SUPPLEMENT_URL,
  MASTER_GENERAL_TERMS_URL,
} from "constants/globals";
import { Heading, Paragraph } from "common/core/typography";

import OrderAndPricingDetails from "./order_and_pricing_details";
import UpdateTitleAgencyMutation from "./update_title_agency_mutation.graphql";
import InvoiceModalQuery, {
  type InvoiceModal_organizationTransaction_OrganizationTransaction,
  type InvoiceModal_organization_Organization,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

const messages = defineMessages({
  title: {
    id: "c5647a88-ced3-408e-bfe9-5777d46081b9",
    defaultMessage: "Welcome to Proof",
  },
  subscriberInfoHeader: {
    id: "fa0b4abf-b648-4343-ad07-38866183db76",
    defaultMessage: "Subscriber information",
  },
  subscriberInformation: {
    id: "9b851cca-4204-4a7c-b31e-3135a0baab16",
    defaultMessage: "This information can be updated in settings at any time.",
  },
  orgNameLabel: {
    id: "5d3a5256-57b1-41ba-baa6-93d9796fcd65",
    defaultMessage: "Organization name",
  },
  cta: {
    id: "05a8ad83-c427-492f-8abe-e9ee032717e6",
    defaultMessage: "Accept and continue",
  },
  disclaimer: {
    id: "cbcdcead-c32d-40ea-9eca-8b9bbbd5e820",
    defaultMessage:
      "By clicking 'Accept and continue', you agree that you are authorized to bind your organization to the <generalTermsLink>General Terms</generalTermsLink>," +
      " as modified by the <titleSupplementLink>Title Supplement</titleSupplementLink> and the <subscriberSupplementLink>Subscriber Supplement</subscriberSupplementLink>, and agree to pay for the {company} Services in accordance with the terms of your order.",
  },
  billingEmailPlaceholder: {
    id: "3aac39cb-5732-491b-8f5e-5bc6d4742b40",
    defaultMessage: "Billing email",
  },
  organizationNamePlaceholder: {
    id: "afc73fe3-abf0-4095-bae1-920385368f57",
    defaultMessage: "Organization name",
  },
  adminFirstNamePlaceholder: {
    id: "8b4040ba-1a4d-4202-bc44-89280beaf33e",
    defaultMessage: "Account admin first name",
  },
  adminLastNamePlaceholder: {
    id: "b6f2556e-b105-42a5-9539-bbb9b1eb8b2d",
    defaultMessage: "Account admin last name",
  },
});

type FormValues = {
  orgName: string | null;
  billingEmail: string | null;
  streetAddress: {
    line1: string;
    line2: string;
    city: string;
    country: string;
    state: string;
    postal: string;
  };
  ownerFirstName?: string | null;
  ownerLastName?: string | null;
};

type Props = {
  invoiceReadOnly: boolean;
  onFormSubmitSuccess(): void;
  organizationTransaction: InvoiceModal_organizationTransaction_OrganizationTransaction | null;
  titleOrganization: InvoiceModal_organization_Organization;
  onboardingOwner: User | null;
  userId: string;
};

type FormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = FormProps & Props;
export type OuterProps = {
  lenderPartnerPayer: PartnerPayerSourcesEnum | null;
  titlePayer: Payer;
  transactionId: string;
};

const validate = (values: FormValues, props: Props) =>
  composeValidators(
    validatePresence({ field: "orgName", label: "Organization name" }),
    validateIf({
      condition: () => Boolean(values.billingEmail),
      validation: validateEmailFormat({ field: "billingEmail" }),
    }),
    validatePresence({ field: "billingEmail", label: "Billing email" }),
    addressValidationRules(values, props, "streetAddress."),
  )(values);

const InvoiceModal = ({
  handleSubmit,
  change,
  untouch,
  invoiceReadOnly,
  onFormSubmitSuccess,
  initialize,
  organizationTransaction,
  titleOrganization,
  onboardingOwner,
  userId,
}: InnerProps) => {
  useEffect(() => {
    segmentTrack(EVENT.LENDER_TITLE_INVOICE_RENDERED, {
      userId,
      transactionId: organizationTransaction?.id,
    });
  }, []);
  const intl = useIntl();
  const updateTitleAgency = useMutation(UpdateTitleAgencyMutation);

  const onSubmit = useCallback<(fv: FormValues) => void>(
    ({ orgName, billingEmail, streetAddress: address, ownerFirstName, ownerLastName }) => {
      let input: UpdateTitleAgencyInput = {
        organizationId: titleOrganization.id,
        organizationName: orgName,
        billingEmail,
        address,
      };

      if (onboardingOwner) {
        input = {
          ...input,
          firstName: ownerFirstName,
          lastName: ownerLastName,
        };
      }

      updateTitleAgency({
        variables: {
          input,
        },
      })
        .catch((error) => {
          captureException(error);
        })
        .then(() => {
          segmentTrack(EVENT.LENDER_TITLE_INVOICE_ACCEPTED, {
            userId,
            transactionId: organizationTransaction?.id,
          });
        })
        .finally(() => {
          onFormSubmitSuccess();
        });
    },
    [onboardingOwner, titleOrganization, userId, organizationTransaction],
  );

  useEffect(() => {
    const titleOrgAddress = titleOrganization.address;
    initialize({
      orgName: titleOrganization.name,
      billingEmail: titleOrganization.billingEmail,
      streetAddress: {
        line1: titleOrgAddress.line1,
        line2: titleOrgAddress.line2,
        city: titleOrgAddress.city,
        country: titleOrgAddress.country,
        state: titleOrgAddress.state,
        postal: titleOrgAddress.postal,
      },
      ownerFirstName: onboardingOwner?.firstName,
      ownerLastName: onboardingOwner?.lastName,
    });
  }, []);

  const lenderName = organizationTransaction!.publicOrganization.name;
  const logoUrl = organizationTransaction!.publicOrganization.logoUrl;
  const notarizeLogoStyle = organizationTransaction!.publicOrganization.organizationBrand.header;
  return (
    <WorkflowModal
      automationId="invoice-modal"
      headerSection={
        <div className={Styles.logoSection}>
          <LogoNav
            noWrapper
            readOnly
            shouldShowEnvIndicator={false}
            orgName={lenderName}
            logoUrl={logoUrl}
            headerStyle={notarizeLogoStyle}
            defaultRightAlignedNotarizeLogo
          />
        </div>
      }
      title={
        <Heading level="h1" textStyle="headingFive" textAlign="center" className={Styles.header}>
          {intl.formatMessage(messages.title)}
        </Heading>
      }
    >
      <OrderAndPricingDetails lenderName={lenderName} />
      <div className={Styles.invoiceForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={Styles.formSection}>
            <FormRow noMargin>
              <FormGroup
                disableFormRowStyle
                errorClassName={Styles.fieldError}
                fields={["billingEmail"]}
              >
                <TextField
                  useStyledInput
                  name="billingEmail"
                  label={intl.formatMessage(messages.billingEmailPlaceholder)}
                  disabled={invoiceReadOnly}
                  useNativeLabel
                />
                <FormGroupErrors fields={["billingEmail"]} />
              </FormGroup>
            </FormRow>
          </div>
          <div className={Styles.formSection}>
            <Heading level="h2" textStyle="subtitle" className={Styles.subHeader}>
              {intl.formatMessage(messages.subscriberInfoHeader)}
            </Heading>
            <Paragraph size="small" className={Styles.descriptionSmall}>
              {intl.formatMessage(messages.subscriberInformation)}
            </Paragraph>
            <FormRow noMargin>
              <FormGroup
                disableFormRowStyle
                errorClassName={Styles.fieldError}
                fields={["orgName"]}
              >
                <TextField
                  useStyledInput
                  name="orgName"
                  label={intl.formatMessage(messages.organizationNamePlaceholder)}
                  disabled={invoiceReadOnly}
                  useNativeLabel
                />
                <FormGroupErrors fields={["orgName"]} />
              </FormGroup>
            </FormRow>
            {onboardingOwner && (
              <FormGroup
                disableFormRowStyle
                errorClassName={Styles.fieldError}
                fields={["ownerFirstName", "ownerLastName"]}
              >
                <FormRow>
                  <MultipartRow>
                    <MultipartColumn width={6}>
                      <TextField
                        useStyledInput
                        name="ownerFirstName"
                        label={intl.formatMessage(messages.adminFirstNamePlaceholder)}
                        disabled={invoiceReadOnly}
                        useNativeLabel
                      />
                    </MultipartColumn>
                    <MultipartColumn width={6}>
                      <TextField
                        useStyledInput
                        name="ownerLastName"
                        label={intl.formatMessage(messages.adminLastNamePlaceholder)}
                        disabled={invoiceReadOnly}
                        useNativeLabel
                      />
                    </MultipartColumn>
                  </MultipartRow>
                </FormRow>
              </FormGroup>
            )}
            <FormRow>
              <AddressSubform
                fieldNamePrefix="streetAddress"
                formName="InvoiceModal"
                change={change}
                useStyledInputs
                showAddressLookup={false}
                untouch={untouch}
                disabledFields={invoiceReadOnly ? Object.values(ADDRESS_FIELDS) : []}
              />
            </FormRow>
          </div>
          <Paragraph size="small" textColor="subtle" className={Styles.descriptionSmall}>
            {intl.formatMessage(messages.disclaimer, {
              company: "Proof",
              titleSupplementLink: (msg: ReactNode[]) => (
                <Link
                  underlined
                  href={TITLE_SUPPLEMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {msg}
                </Link>
              ),
              subscriberSupplementLink: (msg: ReactNode[]) => (
                <Link
                  underlined
                  href={SUBSCRIBER_SUPPLEMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {msg}
                </Link>
              ),
              generalTermsLink: (msg: ReactNode[]) => (
                <Link
                  underlined
                  href={MASTER_GENERAL_TERMS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {msg}
                </Link>
              ),
            })}
          </Paragraph>
          <Button
            automationId="invoice-modal-cta"
            className={Styles.billingCta}
            key="invoice-modal-cta"
            type="submit"
            buttonColor="action"
            variant="primary"
          >
            {intl.formatMessage(messages.cta)}
          </Button>
        </form>
      </div>
    </WorkflowModal>
  );
};

const InvoiceModalWithForm = reduxForm<FormValues, Props>({
  form: "InvoiceModal",
  validate,
})(InvoiceModal);

export default ({ lenderPartnerPayer, titlePayer, transactionId }: OuterProps) => {
  const { viewer } = useViewer<Viewer>();
  let onboardingOwner = null;
  const [activeOrganizationId] = useActiveOrganization();
  const [searchParams] = useSearchParams();
  const { data, loading } = useQuery(InvoiceModalQuery, {
    variables: {
      transactionId,
      organizationId: activeOrganizationId!,
    },
  });
  const userRoles = viewer.user?.roles;
  const isAdmin = userRoles && includesOrgAdminRole(userRoles);
  const isOwner = userRoles?.includes(UserRole.ORGANIZATION_MEMBER_OWNER);
  const isFromNononboardedFlow =
    searchParams.get("flow") === NONONBOARDED && searchParams.get("needSetup");

  // title agency is set to be invoiced when:
  // 1. the transaction's lender is not set to pay the title's side
  // 2. the transaction's title agency is set to pay by customer
  const shouldInvoiceTitleAgency =
    lenderPartnerPayer === PartnerPayerSourcesEnum.ORGANIZATION && titlePayer === Payer.CUSTOMER;

  const [isInvoiceModalOpen, setIsInoviceModalOpen] = useState(shouldInvoiceTitleAgency);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (isFromNononboardedFlow && isOwner) {
    onboardingOwner = viewer.user;
  }
  const organizationTransaction = data?.organizationTransaction;
  const titleOrganization = data?.organization;

  if (organizationTransaction?.__typename !== "OrganizationTransaction") {
    throw new Error(
      `Expected Organization transaction, got ${organizationTransaction?.__typename}`,
    );
  } else if (titleOrganization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${titleOrganization?.__typename}`);
  }

  if (isInvoiceModalOpen) {
    return (
      <InvoiceModalWithForm
        organizationTransaction={organizationTransaction}
        titleOrganization={titleOrganization}
        onFormSubmitSuccess={() => {
          setIsInoviceModalOpen(false);
        }}
        invoiceReadOnly={!isAdmin}
        onboardingOwner={onboardingOwner}
        userId={viewer.user!.id}
      />
    );
  }
  return null;
};
